import { MetaData } from '@sendbird/chat'
import { KeyedMutator } from 'swr'
import useSWRInfinite from 'swr/infinite'

import { COMMENTS_URL } from '@constants/url'

export interface AccessOptions {
  canEdit: boolean
  canDelete: boolean
  canReport: boolean
}

export interface HighestEducation {
  institute: string
  major: string
}

export interface LatestWorkExperience {
  companyName: string
  designation: string
}

export interface RelevantDesignationInfo {
  companyName: string
  designationName: string
}

export interface Creator {
  id: string
  fullName: string
  profilePictureURL: string
  userType: string
  highestEducation?: HighestEducation
  latestWorkExperience?: LatestWorkExperience
  relevantDesignationInfo?: RelevantDesignationInfo
}

export interface Records {
  id: string
  text: string
  createdAt: string
  updatedAt: string
  commentCount: number
  accessOptions: AccessOptions
  creator: Creator
}

export interface CommentProps {
  records: Records[]
  metadata: MetaData
}

type UseComments<Data> = {
  commentsData: Data
  commentsDataIsLoading: boolean
  mutateCommentsData: KeyedMutator<Data>
  setCommentSize: (
    size: number | ((s: number) => number)
  ) => Promise<Data[] | undefined>
  totalCommentsPage: number
  commentsPageSize: number
  totalCommentData: number
}

const getKey = (
  pageIndex: number,
  previousPageData: CommentProps,
  jobPostId: string
) => {
  if (previousPageData && previousPageData?.records?.length === 0) return null

  if (jobPostId) {
    return COMMENTS_URL(jobPostId, pageIndex + 1)
  }

  return null
}

const useComments = (
  jobPostId: string
): UseComments<CommentProps['records']> => {
  const { data, error, mutate, setSize, size } = useSWRInfinite(
    (index, previousData) => getKey(index, previousData, jobPostId)
  )

  const commentsData = data
    ? data?.reduce(
        (prev: CommentProps['records'], curr) => [...prev, ...curr.records],
        []
      )
    : []

  return {
    commentsData,
    commentsDataIsLoading: !data && !error,
    mutateCommentsData: mutate,
    setCommentSize: setSize,
    totalCommentsPage: data && data[0].metaData.totalCount,
    commentsPageSize: size,
    totalCommentData: data && data[0].metaData.totalRecordCount,
  }
}

export default useComments
