import parse from 'html-react-parser'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import useSWRImmutable from 'swr/immutable'

import { appActions } from '@features/app/reducers'
import { useApplicationsActions } from '@features/applications/reducers/ApplicationsReducer'
import { userKycInReview, userKycRejected } from '@features/kyc/helpers'
import {
  GetEmployerProfileAPIResponseData,
  KycVerificationStatus,
} from '@features/kyc/types'

import Button from '@components/button'
import Caption from '@components/typography/caption'
import H3 from '@components/typography/heading-three'

import { useAppDispatch } from '@hooks/redux'

import { EMPLOYMENT_TYPE_OPTIONS } from '@constants/employment-type'
import {
  BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN,
  JOB_SEARCH_CLICK_CARI_KANDIDAT,
} from '@constants/event-tracking'
import { GENDER_OPTIONS } from '@constants/genders'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'
import { EMPLOYERS_PROFILE_URL, GET_DEGREES_URL } from '@constants/url'

import trackEvents from '@utils/track-events'

import BoostIcon from '@common/icons/BoostIcon'
import CariIcon from '@common/icons/CariIcon'
import CheckRoundedIcon from '@common/icons/CheckRoundedIcon'

import useJobDetail from '../../hooks/job-detail'
import type {
  JobDetailHeaderProps,
  JobDetailInfoProps,
  JobDetailTabProps,
} from './type'

const JobDetailHeader: React.FC<JobDetailHeaderProps> = ({
  title,
  imageSrc,
}) => (
  <div className="flex items-center">
    <Image
      src={imageSrc}
      height={24}
      width={24}
      alt="img"
      style={{
        maxWidth: '100%',
        height: 'auto',
      }}
    />
    <div className="ml-2">
      <H3>{title}</H3>
    </div>
  </div>
)

const JobDetailInfo: React.FC<JobDetailInfoProps> = ({
  title,
  content,
  children,
}: JobDetailInfoProps) => (
  <div className="my-3 flex flex-1">
    <div className="flex-1">
      <Caption>{title}</Caption>
    </div>
    <div className="flex flex-1 space-x-4">
      {content && <p className="line-clamp-2 flex-wrap">{content}</p>}
      {children}
    </div>
  </div>
)

const MAX_TAGS = 10
const MIN_TAGS = 5

const JobDetailTab = ({ id }: JobDetailTabProps) => {
  const router = useRouter()
  const { jid } = router.query
  const jobId = jid || id
  const dispatch = useAppDispatch()
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [showedTag, setShowedTag] = useState<number>(5)
  const userType = localStorage.getItem('userType')
  const isPs = userType === 'ps'
  const { jobDetailData } = useJobDetail(
    jobId && typeof jobId === 'string' ? String(jobId) : ''
  )
  const { setJobApplicantsPopupFilters } = useApplicationsActions()

  const { data: degreesData } =
    useSWRImmutable<{ id: string; degreeName: string }[]>(GET_DEGREES_URL)

  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const degreeName = degreesData?.filter(
    (item) => item.id === jobDetailData?.minimumEducationDegreeId
  )[0]?.degreeName
  const handleShowButton = () => {
    if (!showDetail) {
      setShowedTag(MAX_TAGS)
    } else {
      setShowedTag(MIN_TAGS)
    }

    setShowDetail((prevState) => !prevState)
  }

  const handleClickCari = () => {
    if (jobDetailData?.status !== 'Active') {
      dispatch(
        appActions.setModalName(MODAL_NAME.CANDIDATE_SEARCH_NOTICE_MODAL)
      )
    } else {
      trackEvents(JOB_SEARCH_CLICK_CARI_KANDIDAT, {
        entry_point: 'Loker',
      })

      setJobApplicantsPopupFilters({
        genders:
          jobDetailData?.requiredGender !== 'Both' &&
          jobDetailData?.requiredGender != null
            ? jobDetailData?.requiredGender
            : undefined,
        homeAddresses: jobDetailData?.location?.shortAddress
          ? [
              {
                cityName:
                  (jobDetailData?.location?.shortAddress as string) ??
                  undefined,
              },
            ]
          : undefined,
        minAge: jobDetailData?.minimumAge
          ? Number(jobDetailData?.minimumAge)
          : undefined,
        maxAge: jobDetailData?.maximumAge
          ? Number(jobDetailData?.maximumAge)
          : undefined,
        minSalary: jobDetailData?.minimumSalary ?? undefined,
        maxSalary: jobDetailData?.maximumSalary ?? undefined,
      })

      router.push({
        pathname: PATHS.SEARCH_CANDIDATE,
        query: {
          jid: id,
        },
      })
    }
  }
  const handleClickBoost = () => {
    if (jobDetailData?.status !== 'Active') {
      dispatch(appActions.setModalName(MODAL_NAME.BOOST_NOTICE_MODAL))
      return
    }

    if (
      userKycInReview({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_INREVIEW_NOTIFY_MODAL))
    } else if (
      userKycRejected({
        employerKyc: employerProfileData?.kycStatus as KycVerificationStatus,
        companyKyc: employerProfileData?.company
          ?.kycStatus as KycVerificationStatus,
      })
    ) {
      dispatch(appActions.setModalName(MODAL_NAME.KYC_REVERIFY_REJECTED_MODAL))
    } else {
      trackEvents(BOOST_CLICK_BOOST_BUTTON_JOB_SCREEN)
      router.push({
        pathname: '/boost',
        query: {
          jid: id,
        },
      })
    }
  }

  const renderTag = () => {
    if (!jobDetailData) return null
    return (
      <div className="flex w-full flex-wrap">
        {jobDetailData?.attributeTags.slice(0, showedTag).map((data) => (
          <div
            key={data?.tagName}
            className="mb-2 mr-2 inline-block rounded-3xl border border-[#A792D8] bg-[#F1EBFF] px-2 text-sm text-[#7B63B4]"
          >
            {data.tagName}
          </div>
        ))}
        {jobDetailData?.attributeTags.length > MIN_TAGS && (
          <button
            type="button"
            onClick={handleShowButton}
            className="cursor-pointer text-sm text-[#4460AC] underline"
          >
            {showDetail ? 'Lihat lebih sedikit' : 'Lihat selengkapnya'}
          </button>
        )}
      </div>
    )
  }

  return (
    <div className="p-4 border-t">
      <div className="border-b border-gray-100 py-4">
        <JobDetailHeader
          title="Informasi utama"
          imageSrc="/assets/icons/ic_basic_information.svg"
        />
        <JobDetailInfo
          title="Tipe pekerjaan"
          content={jobDetailData?.jobProfiles?.[0]?.jobProfileName}
        />
        <JobDetailInfo
          title="Kebutuhan karyawan"
          content={`${jobDetailData?.openings} karyawan`}
        />
        <JobDetailInfo
          title="Lokasi pekerjaan"
          content={jobDetailData?.location?.locationAddress}
        />
        <JobDetailInfo
          title="Jenis pekerjaan "
          content={
            EMPLOYMENT_TYPE_OPTIONS.find(
              (opt) => opt.value === jobDetailData?.employmentType
            )?.label
          }
        >
          {jobDetailData?.estimatedJobDurationInDays !== 0 &&
          jobDetailData?.estimatedJobDurationInDays ? (
            <Caption>
              ({jobDetailData?.estimatedJobDurationInDays} hari)
            </Caption>
          ) : null}
        </JobDetailInfo>
        <JobDetailInfo title="Gaji (per bulan)">
          <div className="flex flex-wrap space-x-1">
            <NumberFormat
              fixedDecimalScale={false}
              prefix="Rp. "
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              displayType="text"
              value={jobDetailData?.minimumSalary}
              className="border-0 px-0 py-0"
            />
            <p className="px-1">-</p>
            <NumberFormat
              fixedDecimalScale={false}
              prefix="Rp."
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              displayType="text"
              value={jobDetailData?.maximumSalary}
              className="border-0 px-0 py-0"
            />
          </div>
        </JobDetailInfo>
        <JobDetailInfo title="Deskripsi pekerjaan">
          <div>{parse(jobDetailData?.description || '-')}</div>
        </JobDetailInfo>
      </div>

      <div className="py-4">
        <JobDetailHeader
          title="Persyaratan"
          imageSrc="/assets/icons/ic_requirements.svg"
        />
        <JobDetailInfo title="Keahlian yang dibutuhkan">
          {jobDetailData?.attributeTags.length !== 0 && renderTag()}
        </JobDetailInfo>

        <JobDetailInfo title="Pendidikan terakhir" content={degreeName} />
        <JobDetailInfo
          title="Pengalaman kerja"
          content={`${
            jobDetailData?.requiredExperienceInYears !== 0
              ? jobDetailData?.requiredExperienceInYears
              : '<1'
          } tahun`}
        />

        <JobDetailInfo title="Usia">
          {jobDetailData?.minimumAge ? (
            <div className="flex">
              <p>{jobDetailData?.minimumAge} tahun</p>
              {jobDetailData?.maximumAge && (
                <>
                  <p className="px-1">-</p>
                  <p>{jobDetailData?.maximumAge} tahun</p>
                </>
              )}
            </div>
          ) : (
            <p>-</p>
          )}
        </JobDetailInfo>
        <JobDetailInfo
          title="Jenis kelamin"
          content={
            GENDER_OPTIONS.find(
              (opt) => opt.value === jobDetailData?.requiredGender
            )?.label
          }
        />
        <JobDetailInfo
          title="Dokumen untuk asesmen lanjutan"
          content={`${jobDetailData?.isCVRequired ? 'CV' : '-'}`}
        />
      </div>
      <div className="border-b border-gray-100 py-4">
        <JobDetailHeader
          title="Informasi tambahan"
          imageSrc="/assets/icons/ic_basic_information.svg"
        />

        <JobDetailInfo title="Benefit">
          <div className="flex flex-wrap items-center text-center">
            {jobDetailData?.benefitTags?.map((item) => (
              <div
                key={item.tagName}
                className="my-1 mr-1 flex rounded-md bg-secondary-green px-2 py-1"
              >
                <CheckRoundedIcon className="mr-1" />
                {item.tagName}
              </div>
            ))}
          </div>
        </JobDetailInfo>
      </div>
      <div className="border-b border-gray-100 py-4 ">
        <JobDetailHeader
          title="Media untuk ditampilkan di loker (video atau gambar)"
          imageSrc="/assets/icons/ic_album_add.svg"
        />
        <div className="flex">
          {jobDetailData &&
            jobDetailData.medias.length > 0 &&
            jobDetailData.medias.map((data) => {
              const mediaType = data?.mimeType.split('/')
              switch (mediaType[0]) {
                case 'video':
                  return (
                    <Link
                      href={data?.linkURL}
                      passHref
                      key={data?.link}
                      legacyBehavior
                    >
                      <a target="_black" className="cursor-pointer px-2 py-2">
                        <video className="h-[80px] w-[80px]">
                          <source src={data?.linkURL} />
                          <track kind="captions" />
                        </video>
                      </a>
                    </Link>
                  )
                case 'image':
                  return (
                    <Link
                      href={data?.linkURL}
                      key={data?.link}
                      passHref
                      legacyBehavior
                    >
                      <a target="_blank" className="px-2 py-2">
                        <Image
                          src={data?.linkURL}
                          alt="job media"
                          height={80}
                          width={80}
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                          }}
                        />
                      </a>
                    </Link>
                  )
                default:
                  return ''
              }
            })}
        </div>
      </div>

      {!isPs && (
        <div className="h-[80px] sticky bottom-[-15px] right-0 left-0 p-8 bg-neutral-gray-25 mx-[-15px] border-[1px] !mb-[-15px]">
          <div className="flex justify-around items-center mt-[-29px]">
            <Button
              type="button"
              appearance="text"
              className="items-center justify-between text-primary-blue"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleClickCari()
              }}
            >
              <CariIcon fill="#4460AC" />
              <span>Cari</span>
            </Button>

            <span className="border-l border-gray-300 h-[78px]  mx-4" />

            <Button
              type="button"
              appearance="text"
              className="items-center justify-between text-primary-blue"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClickBoost()
              }}
            >
              <BoostIcon stroke="#4460AC" />
              <span>Boost</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default JobDetailTab
