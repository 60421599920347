import { MetaData } from '@sendbird/chat'
import { KeyedMutator } from 'swr'
import useSWRInfinite from 'swr/infinite'

import { CHILD_COMMENTS_URL } from '@constants/url'

export interface AccessOptions {
  canEdit: boolean
  canDelete: boolean
  canReport: boolean
}

export interface HighestEducation {
  institute: string
  major: string
}

export interface LatestWorkExperience {
  companyName: string
  designation: string
}

export interface RelevantDesignationInfo {
  companyName: string
  designationName: string
}

export interface Creator {
  id: string
  fullName: string
  profilePictureURL: string
  userType: string
  highestEducation?: HighestEducation
  latestWorkExperience?: LatestWorkExperience
  relevantDesignationInfo?: RelevantDesignationInfo
}

interface Records {
  id: string
  text: string
  createdAt: string
  updatedAt: string
  commentCount: number
  accessOptions: AccessOptions
  creator: Creator
}

export interface CommentProps {
  records: Records[]
  metadata: MetaData
}

type UseComments<Data> = {
  childCommentsData: Data
  childCommentsDataIsLoading: boolean
  mutateChildCommentsData: KeyedMutator<Data>
  setChildCommentSize: (
    size: number | ((s: number) => number)
  ) => Promise<Data[] | undefined>
  totalChildCommentsPage: number
  childCommentsPageSize: number
}

const getKey = (
  pageIndex: number,
  previousPageData: CommentProps,
  jobPostId: string,
  parentCommentId: string
) => {
  if (previousPageData && previousPageData?.records?.length === 0) return null

  if (jobPostId && parentCommentId) {
    return CHILD_COMMENTS_URL(jobPostId, parentCommentId, pageIndex + 1)
  }

  return null
}

const useComments = (
  jobPostId: string,
  parentCommentId: string
): UseComments<CommentProps['records']> => {
  const { data, error, mutate, setSize, size } = useSWRInfinite(
    (index, previousPageData) =>
      getKey(index, previousPageData, jobPostId, parentCommentId)
  )

  const childCommentsData = data
    ? data?.reduce(
        (prev: CommentProps['records'], curr) => [...prev, ...curr.records],
        []
      )
    : []

  return {
    childCommentsData,
    childCommentsDataIsLoading: !data && !error,
    mutateChildCommentsData: mutate,
    setChildCommentSize: setSize,
    totalChildCommentsPage: data && data[0].metaData.totalCount,
    childCommentsPageSize: size,
  }
}

export default useComments
