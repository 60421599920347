import Image from 'next/image'
import React, { useEffect } from 'react'
import useSWRImmutable from 'swr/immutable'

import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { useAppSelector } from '@hooks/redux'

import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import SendIcon from '@common/icons/SendIcon'

import { useJobsActions } from '../../reducers'
import { CommentInputProps } from './type'

const CommentInput = ({
  setCommentText,
  onSendComment,
  commentText,
  replyData,
  setReplyData,
}: CommentInputProps) => {
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const { resetEditCommentDataState } = useJobsActions()

  const editCommentData = useAppSelector(
    (state) => state.jobReducer.editCommentData
  )

  useEffect(() => {
    if (editCommentData.editCommentText) {
      setCommentText(editCommentData.editCommentText)
    }
  }, [editCommentData])

  return (
    <div className="sticky bottom-0 !mt-auto ">
      {replyData && (
        <div className="flex justify-between bg-[#F8F8F8] px-2 py-1">
          <div className="flex">
            <p className="mr-1 text-[#717171]">Membalas</p>
            <p className="font-semibold text-[#717171]">{replyData.fullName}</p>
          </div>
          <button
            className="text-[#4460AC]"
            onClick={() => setReplyData(null)}
            type="button"
            tabIndex={0}
          >
            Batalkan
          </button>
        </div>
      )}
      {editCommentData.commentId && (
        <div className="flex justify-between bg-[#F8F8F8] px-2 py-1">
          <div className="flex">
            <p className="mr-1 text-[#717171]">Edit comment</p>
          </div>
          <button
            className="text-[#4460AC]"
            onClick={() => {
              resetEditCommentDataState()
              setCommentText('')
            }}
            type="button"
            tabIndex={0}
          >
            Batalkan
          </button>
        </div>
      )}
      <div className="p-4 border-t bg-white">
        <div className="flex items-center">
          <div className="relative mr-2 h-5 w-5 overflow-hidden rounded-full border">
            <Image
              alt="icon"
              src={
                employerProfileData?.profilePictureURL ||
                '/assets/icons/ic_profile.svg'
              }
              fill
              sizes="100vw"
            />
          </div>
          <p className="font-semibold">{employerProfileData?.fullName}</p>
        </div>
        <div className="flex mt-2">
          <input
            className="w-full"
            onChange={(e) => setCommentText(e.currentTarget.value)}
            placeholder="Tulis komentar-mu di sini..."
            value={commentText}
          />
          <button onClick={onSendComment} type="button">
            <SendIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default CommentInput
