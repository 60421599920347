export const REPORT_REASONS = {
  SPAM: 'Spam',
  PORNOGRAPHY: 'Pornography',
  VIOLENCE: 'Violence',
  HATE_SPEECH: 'HateSpeech',
  WRONG_INFORMATION: 'WrongInformation',
  BULLYING_OR_HARASSMENT: 'BullyingOrHarassment',
  FRAUD: 'Fraud',
  OTHER: 'Other',
}
