import { Popover, Transition } from '@headlessui/react'
import Image from 'next/image'
import React, { Fragment } from 'react'
import { toast } from 'react-toastify'
import useSWRImmutable from 'swr/immutable'

import useChildComments from '@features/chats/hooks/use-child-comments'
import useComments from '@features/chats/hooks/use-comments'
import { deleteComment } from '@features/chats/reducers/thunk-actions'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { useAppDispatch } from '@hooks/redux'

import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import errorFormat from '@utils/error-format'

import { useJobsActions } from '../../reducers'
import { CommentToggleProps } from './type'

const CommentToggle = ({
  commentId,
  getChildCommentData,
  jobPostId,
  commentText,
  creatorId,
}: CommentToggleProps) => {
  const dispatch = useAppDispatch()
  const { setEditCommentDataState, setReportCommentModalState } =
    useJobsActions()
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)

  const { mutateCommentsData } = useComments(jobPostId as string)

  const { mutateChildCommentsData } = useChildComments(
    jobPostId as string,
    getChildCommentData
  )

  const onDeleteComment = async () => {
    try {
      await dispatch(deleteComment({ jobPostId, commentId })).unwrap()
      mutateCommentsData()

      if (getChildCommentData) {
        mutateChildCommentsData()
      }
    } catch (error) {
      toast.error(errorFormat(error))
    }
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`${open ? '' : 'text-opacity-90'} group block rounded-md bg-transparent text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:text-opacity-100 lg:inline-flex lg:items-center lg:space-x-2`}
          >
            <Image
              alt="icon"
              src="/assets/icons/ic_meetballs_menu.svg"
              width={15}
              height={15}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-5 z-20 w-max -translate-x-full transform">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative bg-white w-[7.5rem]">
                  {creatorId !== employerProfileData?.id ? (
                    <button
                      type="button"
                      className="mx-auto flex w-full items-center px-4 py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary-blue focus-visible:ring-opacity-50 hover:bg-[#F4F6FB] border-b"
                      onClick={() => {
                        setReportCommentModalState({ open: true, commentId })
                        close()
                      }}
                    >
                      <p className="font-medium text-primary-blue">Laporkan</p>
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="mx-auto flex w-full items-center px-4 py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary-blue focus-visible:ring-opacity-50 hover:bg-[#F4F6FB] border-b"
                        onClick={() => {
                          setEditCommentDataState({
                            commentId,
                            editCommentText: commentText,
                          })
                          close()
                        }}
                      >
                        <p className="font-medium text-primary-blue">Edit</p>
                      </button>
                      <button
                        type="button"
                        className="mx-auto flex w-full items-center px-4 py-3 transition duration-150 ease-in-out focus:outline-none focus-visible:ring focus-visible:ring-primary-blue focus-visible:ring-opacity-50 hover:bg-[#F4F6FB]"
                        onClick={() => {
                          onDeleteComment()
                          close()
                        }}
                      >
                        <p className="font-medium text-[#CC444B]">Hapus</p>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default CommentToggle
