import React from 'react'

import Drawer from '@components/drawer/Drawer'

import { useAppSelector } from '@hooks/redux'

import { DrawerSize } from '@constants/drawer'

import CloseIcon from '@common/icons/CloseIcon'

import CommentTab from './CommentTab'
import JobDetailTab from './JobDetailTab'
import JobInfo from './JobInfo'
import type { Props } from './type'

const Slider = ({ id, isOpen, onClose, changeJobStatus }: Props) => {
  const { selectedTab } = useAppSelector(
    (state) => state.jobReducer.jobDetailDrawer
  )

  return (
    <Drawer
      articleClassName="!p-0 !space-y-0"
      isOpen={isOpen}
      title={
        <button
          className="ml-auto float-right mb-[32px]"
          type="button"
          onClick={() => {
            onClose()
          }}
        >
          <CloseIcon className="text-primary-gray" width={20} height={20} />
        </button>
      }
      onClose={onClose}
      hideCloseButton
      size={DrawerSize.MEDIUM}
      titleContainerClassName="py-4"
    >
      <JobInfo changeJobStatus={changeJobStatus} />
      {selectedTab === 'detail' && <JobDetailTab id={id} />}
      {selectedTab === 'comment' && <CommentTab id={id} />}
    </Drawer>
  )
}

export default Slider
