import { useRouter } from 'next/router'
import React from 'react'

import { appActions } from '@features/app/reducers'
import Chip from '@features/jobs/components/chip'
import JobCardQuota from '@features/jobs/components/job-card-quota/JobCardQuota'

import Button from '@components/button'
import Switch from '@components/form/switch'
import Caption from '@components/typography/caption'
import H1 from '@components/typography/heading-one'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { KYC_STATUS } from '@constants/kyc-status'
import MODAL_NAME from '@constants/modal-name'
import { PATHS } from '@constants/paths'

import { formatDateInID } from '@utils/date-calculation'

import { JobPost } from '@custom-types/job-post'

import useJobDetail from '../../hooks/job-detail'
import { jobActions, useJobsActions } from '../../reducers'
import ExpiredDateBanner from '../expired-date-banner'
import JobStatusBadge from '../job-status-badge'
import { JobDetailInfoProps, JobInfoProps } from "./type"
import Tab from './Tab'

const JobDetailInfo: React.FC<JobDetailInfoProps> = ({
  title,
  content,
  children,
}: JobDetailInfoProps) => (
  <div className="my-3 flex flex-1">
    <div className="flex-1">
      <Caption>{title}</Caption>
    </div>
    <div className="flex flex-1 space-x-4">
      {content && <p className="line-clamp-2 flex-wrap">{content}</p>}
      {children}
    </div>
  </div>
)

const JobInfo = ({ changeJobStatus }: JobInfoProps) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const id = useAppSelector((state) => state.jobReducer.jobDetailDrawer.jobId)
  const { jid } = router.query
  const jobId = jid || id
  const userType = localStorage.getItem('userType')
  const isPs = userType === 'ps'
  const profile = useAppSelector((state) => state.profileReducer)
  const { jobDetailData } = useJobDetail(
    jobId && typeof jobId === 'string' ? String(jobId) : ''
  )

  const jobsActions = useJobsActions()

  const onCloseDrawer = () => {
    jobsActions.resetJobCommentDrawerState()
  }

  const renderJobStatus = () => (
    <div className="mb-6 mt-4 flex w-full items-center justify-between">
      <Caption>Status</Caption>
      <div className="flex w-1/2 justify-start">
        <JobStatusBadge jobStatus={jobDetailData?.status || ''} />
        {jobDetailData?.status === 'Active' ||
        jobDetailData?.status === 'InActive' ? (
          <div className="ml-4">
            <Switch
              onChange={() => {
                if (
                  profile.kycStatus !== KYC_STATUS.APPROVED ||
                  profile.company?.kycStatus !== KYC_STATUS.APPROVED
                ) {
                  dispatch(appActions.setModalName(MODAL_NAME.KYC_NOTICE_MODAL))
                  return true
                }

                if (jobDetailData?.status === 'Active') {
                  dispatch(appActions.setModalName(MODAL_NAME.QUESTION_MODAL))
                  dispatch(jobActions.setModalId(jobDetailData.id))
                } else {
                  changeJobStatus(
                    jobId ? (jobId as string) : (id as string),
                    jobDetailData.status
                  )
                }
                return true
              }}
              value={jobDetailData?.status === 'Active'}
            />
          </div>
        ) : null}
      </div>
    </div>
  )

  return (
    <div className="px-4">
      <div className="flex justify-between border-b pb-3">
        <H1 className="max-">{jobDetailData?.title}</H1>
        {!isPs && (
          <div className="w-[120px]">
            <Button
              appearance="outline-primary"
              type="button"
              className="h-[44px] w-[120px]"
              onClick={() => {
                onCloseDrawer()
                router.push({
                  pathname: PATHS.EDIT_JOB,
                  query: {
                    jlid: jobDetailData?.id,
                  },
                })
              }}
            >
              Edit Loker
            </Button>
          </div>
        )}
      </div>
      <div className="border-b border-gray-100 pb-4">
        {!isPs && (
          <div className="mb-6 mt-4 flex w-full items-center justify-between">
            <Caption>Paket</Caption>
            <div className="flex w-1/2 justify-start">
              <Chip type={jobDetailData?.productPackage?.productName} />
              {jobDetailData?.isFree && <Chip type="Free" className="ml-2" />}
            </div>
          </div>
        )}
        {!isPs && renderJobStatus()}
        <ExpiredDateBanner jobExpiredDate={jobDetailData?.expiryAt || ''} />
        {jobDetailData?.postedAt && (
          <JobDetailInfo
            title="Waktu posting"
            content={
              jobDetailData
                ? formatDateInID(jobDetailData.postedAt, 'dd MMMM yyyy, HH.mm')
                : '-'
            }
          />
        )}
      </div>
      {!isPs && (
        <JobCardQuota
          totalQuota={
            jobDetailData?.productPackage?.passiveApplicantQuota as number
          }
          quotaUsed={jobDetailData?.metric?.passiveApplicantCount}
          jlType={
            jobDetailData?.productPackage
              ?.productName as JobPost['productPackage']['productName']
          }
          jobId={jobId && typeof jobId === 'string' ? String(jobId) : ''}
          status={jobDetailData?.status as string}
        />
      )}
      <Tab />
    </div>
  )
}

export default JobInfo
