import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import { toast } from 'react-toastify'

import { reportComment } from '@features/chats/reducers/thunk-actions'

import Button from '@components/button'
import Modal from '@components/modal'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import { REPORT_REASONS } from '@constants/report-reasons'

import errorFormat from '@utils/error-format'

import { useJobsActions } from '../../reducers'
import { ReportCommentFormValues, ReportCommentModalProps } from './types'

const ReportCommentModal = ({ jobPostId }: ReportCommentModalProps) => {
  const dispatch = useAppDispatch()
  const { open } = useAppSelector(
    (state) => state.jobReducer.reportCommentModal
  )
  const { commentId } = useAppSelector(
    (state) => state.jobReducer.reportCommentModal
  )

  const { setReportCommentModalState, resetReportCommentModalState } =
    useJobsActions()

  const onCloseModal = () => {
    setReportCommentModalState({ open: false })
  }

  const initialValues: ReportCommentFormValues = {
    reason: '',
    reasonText: '',
  }

  const onSubmit = async (values: ReportCommentFormValues) => {
    try {
      const reportCommentData: {
        jobPostId: string
        commentId: string
        reason: string
        reasonText?: string
      } = {
        jobPostId,
        commentId,
        reason: values.reason,
      }

      if (values.reasonText) {
        reportCommentData.reasonText = values.reasonText
      }

      await dispatch(reportComment(reportCommentData))
      resetReportCommentModalState()
    } catch (error) {
      toast.error(errorFormat(error))
    }
  }

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      isHeaderHidden
      rootContentClassname="w-[30rem] !p-0"
    >
      <div className="p-4 border-b">
        <p className="text-xl font-semibold">Laporkan komentar</p>
      </div>
      <div className="p-4">
        <p className="font-semibold">Kenapa anda laporkan komentar ini?</p>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className="flex flex-col gap-y-4 mt-4">
                <label htmlFor="spam">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="spam"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.SPAM}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.SPAM)
                    }}
                  />
                  Spam
                </label>
                <label htmlFor="pornography">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="pornography"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.PORNOGRAPHY}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.PORNOGRAPHY)
                    }}
                  />
                  Pornografi
                </label>
                <label htmlFor="violence">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="violence"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.VIOLENCE}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.VIOLENCE)
                    }}
                  />
                  Kekerasan
                </label>
                <label htmlFor="hateSpeech">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="hateSpeech"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.HATE_SPEECH}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.HATE_SPEECH)
                    }}
                  />
                  Ujaran kebencian
                </label>
                <label htmlFor="wrongInfo">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="wrongInfo"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.WRONG_INFORMATION}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.WRONG_INFORMATION)
                    }}
                  />
                  Informasi yang salah
                </label>
                <label htmlFor="bullying">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="bullying"
                    name="reason"
                    type="radio"
                    checked={
                      values.reason === REPORT_REASONS.BULLYING_OR_HARASSMENT
                    }
                    onChange={() => {
                      setFieldValue(
                        'reason',
                        REPORT_REASONS.BULLYING_OR_HARASSMENT
                      )
                    }}
                  />
                  Penindasan atau pelecehan
                </label>
                <label htmlFor="fraud">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="fraud"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.FRAUD}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.FRAUD)
                    }}
                  />
                  Penipuan
                </label>
                <label htmlFor="other">
                  <input
                    className={classNames(
                      'w-[24px]',
                      'h-[24px]',
                      'border-2',
                      'rounded-circle',
                      'border-gray-500',
                      'active:border-gray-500',
                      'hover:cursor-pointer',
                      'mr-2',
                      'text-primary-blue',
                      'checked:bg-[length:150%_150%]'
                    )}
                    id="other"
                    name="reason"
                    type="radio"
                    checked={values.reason === REPORT_REASONS.OTHER}
                    onChange={() => {
                      setFieldValue('reason', REPORT_REASONS.OTHER)
                    }}
                  />
                  Lainnya
                </label>
              </div>
              <div className="flex gap-x-4 mt-4">
                <Button
                  appearance="outline-primary"
                  type="button"
                  className="flex-1"
                  onClick={onCloseModal}
                >
                  Batal
                </Button>
                <Button
                  appearance="primary"
                  type="submit"
                  className="flex-1"
                  disabled={!values.reason}
                >
                  Laporkan
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default ReportCommentModal
