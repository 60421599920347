import classNames from 'classnames'
import React from 'react'

import { useAppSelector } from '@hooks/redux'

import { useJobsActions } from '../../reducers'

const Tab = () => {
  const { selectedTab } = useAppSelector(
    (state) => state.jobReducer.jobDetailDrawer
  )

  const jobActions = useJobsActions()

  return (
    <div className="border-t flex mt-4">
      <button
        type="button"
        onClick={() =>
          jobActions.setJobDetailDrawerState({ selectedTab: 'detail' })
        }
        className="flex-1 py-2"
      >
        <p
          className={classNames(
            'inline-block',
            selectedTab === 'detail' ? 'text-primary-blue' : 'text-[#717171]',
            selectedTab === 'detail'
              ? 'border-b-2 border-b-primary-blue pb-1'
              : '',
            'font-semibold'
          )}
        >
          Seputar loker
        </p>
      </button>
      <button
        type="button"
        onClick={() =>
          jobActions.setJobDetailDrawerState({ selectedTab: 'comment' })
        }
        className="flex-1 py-2"
      >
        <p
          className={classNames(
            'inline-block',
            selectedTab === 'comment' ? 'text-primary-blue' : 'text-[#717171]',
            selectedTab === 'comment'
              ? 'border-b-2 border-b-primary-blue pb-1'
              : '',
            'font-semibold'
          )}
        >
          Komentar
        </p>
      </button>
    </div>
  )
}

export default Tab
