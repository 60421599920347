import Image from 'next/image'
import React from 'react'

import useChildComments from '@features/chats/hooks/use-child-comments'
import useComments from '@features/chats/hooks/use-comments'

import { useAppSelector } from '@hooks/redux'

import CommentCard from './CommentCard'
import { CommentListProps } from './type'

const CommentList = ({
  jobPostId,
  getChildCommentData,
  setReplyData,
  setGetChildCommentData,
}: CommentListProps) => {
  const editCommentData = useAppSelector(
    (state) => state.jobReducer.editCommentData
  )

  const { commentsData, totalCommentsPage, commentsPageSize, setCommentSize } =
    useComments(jobPostId as string)

  const {
    childCommentsData,
    setChildCommentSize,
    childCommentsPageSize,
    totalChildCommentsPage,
  } = useChildComments(jobPostId as string, getChildCommentData)

  return (
    <div className="px-4 flex flex-col gap-y-4">
      {commentsData &&
        commentsData.map((commentDetailData) => (
          <div key={commentDetailData.id}>
            <CommentCard
              data={commentDetailData}
              getChildCommentData={getChildCommentData}
              jobPostId={jobPostId}
            />
            <div className="ml-8">
              <button
                className="iems-center mt-2 flex"
                onClick={() =>
                  setReplyData({
                    fullName: commentDetailData.creator.fullName,
                    commentId: commentDetailData.id,
                  })
                }
                type="button"
                tabIndex={0}
                disabled={editCommentData.commentId !== ''}
              >
                <Image
                  alt="icon"
                  src="/assets/icons/ic_reply_comment.svg"
                  height={12}
                  width={12}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
                <p className="ml-2 text-[0.75rem] text-[#717171]">Balas</p>
              </button>
              {commentDetailData.commentCount > 0 &&
                getChildCommentData !== commentDetailData.id && (
                  <button
                    className="mt-2 text-sm font-semibold text-primary-blue"
                    onClick={() => setGetChildCommentData(commentDetailData.id)}
                    type="button"
                    tabIndex={0}
                  >
                    <p>Lihat {commentDetailData.commentCount} balasan</p>
                  </button>
                )}
              {childCommentsData &&
                getChildCommentData === commentDetailData.id && (
                  <div className="my-4 flex flex-col gap-y-4">
                    {childCommentsData.map((childComment) => (
                      <CommentCard
                        data={childComment}
                        getChildCommentData={getChildCommentData}
                        jobPostId={jobPostId}
                        key={childComment.id}
                      />
                    ))}
                  </div>
                )}
              {childCommentsPageSize < totalChildCommentsPage &&
                getChildCommentData === commentDetailData.id && (
                  <div className="mt-4 flex justify-center text-sm">
                    <button
                      type="button"
                      tabIndex={0}
                      className="font-semibold text-[#717171]"
                      onClick={() =>
                        setChildCommentSize(childCommentsPageSize + 1)
                      }
                    >
                      Tampilkan balasan lainnya
                    </button>
                  </div>
                )}
            </div>
          </div>
        ))}
      {commentsPageSize < totalCommentsPage && (
        <div className="mb-4 flex justify-center">
          <button
            type="button"
            tabIndex={0}
            className="font-semibold text-[#717171]"
            onClick={() => setCommentSize(commentsPageSize + 1)}
          >
            Tampilkan komentar lainnya
          </button>
        </div>
      )}
    </div>
  )
}

export default CommentList
