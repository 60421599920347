import { formatDistanceToNow } from 'date-fns'
import localeId from 'date-fns/locale/id'
import Image from 'next/image'
import React from 'react'
import useSWRImmutable from 'swr/immutable'

import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { EMPLOYERS_PROFILE_URL } from '@constants/url'

import CommentToggle from './CommentToggle'
import { CommentCardProps } from './type'

const CommentCard = ({
  data,
  getChildCommentData,
  jobPostId,
}: CommentCardProps) => {
  const { creator, text, createdAt, id } = data
  const { data: employerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)

  return (
    <div className="flex">
      <div>
        <div className="relative mr-2 mt-2 h-[20px] w-[20px] overflow-hidden rounded-full border">
          <Image
            alt="profilePicture"
            src={creator.profilePictureURL || '/assets/icons/ic_profile.svg'}
            fill
          />
        </div>
      </div>
      <div className="flex w-[96%] items-start justify-between rounded-lg bg-[#F8F8F8] p-2">
        <div className="w-full">
          <div className="mb-2">
            <div className="flex justify-between">
              <div className="flex items-center gap-x-2">
                <p className="font-semibold">{creator.fullName}</p>
                {creator.id === employerProfileData?.id && (
                  <div className="px-2 py-0.5 bg-primary-blue text-white rounded-2xl text-sm">
                    Anda
                  </div>
                )}
              </div>
              <CommentToggle
                commentId={id}
                getChildCommentData={getChildCommentData}
                jobPostId={jobPostId}
                commentText={text}
                creatorId={creator.id}
              />
            </div>
            <p className="text-[0.75rem] text-[#717171]">
              {creator.highestEducation &&
                `Lulusan dari ${creator.highestEducation.institute}`}
            </p>
            <p className="text-[0.75rem] text-[#717171]">
              {formatDistanceToNow(new Date(createdAt), {
                locale: localeId,
                addSuffix: true,
              })}
            </p>
          </div>
          <p className="break-words">{text}</p>
        </div>
      </div>
    </div>
  )
}

export default CommentCard
