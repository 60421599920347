import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

import useChildComments from '@features/chats/hooks/use-child-comments'
import useComments from '@features/chats/hooks/use-comments'
import {
  editComment,
  postComment,
} from '@features/chats/reducers/thunk-actions'

import { useAppDispatch, useAppSelector } from '@hooks/redux'

import errorFormat from '@utils/error-format'

import { useJobsActions } from '../../reducers'
import ReportCommentModal from '../report-comment-modal'
import CommentInput from './CommentInput'
import CommentList from './CommentList'
import { CommentTabProps } from './type'

const CommentTab = ({ id }: CommentTabProps) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { jid } = router.query
  const jobPostId = jid || id

  const { editCommentData } = useAppSelector((state) => state.jobReducer)
  const { resetEditCommentDataState } = useJobsActions()

  const [commentText, setCommentText] = useState<string>('')
  const [replyData, setReplyData] = useState<{
    fullName: string
    commentId: string
  } | null>()
  const [getChildCommentData, setGetChildCommentData] = useState<string>('')

  const { mutateCommentsData, commentsData, totalCommentData } = useComments(
    jobPostId as string
  )

  const { mutateChildCommentsData } = useChildComments(
    jobPostId as string,
    getChildCommentData
  )

  const onSendComment = async () => {
    try {
      const commentData: {
        jobPostId: string
        commentText: string
        commentId?: string
        parentCommentId?: string
      } = {
        jobPostId: jobPostId as string,
        commentText,
      }

      if (replyData) {
        commentData.parentCommentId = replyData.commentId
      }

      if (editCommentData.commentId) {
        commentData.commentId = editCommentData.commentId
        await dispatch(editComment(commentData))
      } else {
        await dispatch(postComment(commentData))
      }

      if (replyData) {
        if (getChildCommentData === '') {
          setGetChildCommentData(replyData.commentId)
        } else {
          mutateChildCommentsData()
        }
      }

      setCommentText('')
      setReplyData(null)
      resetEditCommentDataState()
      mutateCommentsData()
    } catch (error) {
      toast.error(errorFormat(error))
    }
  }

  return (
    <div className="border-t flex flex-col h-full">
      <div className="p-4">
        <p className="font-semibold text-lg">
          Komentar ({commentsData && totalCommentData})
        </p>
      </div>
      <CommentList
        jobPostId={jobPostId as string}
        getChildCommentData={getChildCommentData}
        setReplyData={setReplyData}
        setGetChildCommentData={setGetChildCommentData}
      />
      <CommentInput
        setCommentText={setCommentText}
        onSendComment={onSendComment}
        commentText={commentText}
        replyData={replyData}
        setReplyData={setReplyData}
      />
      <ReportCommentModal jobPostId={jobPostId as string} />
    </div>
  )
}

export default CommentTab
