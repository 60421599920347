import React from 'react'

const SendIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.0934 11.3453L12.6801 5.63867C5.01344 1.79867 1.86677 4.94533 5.70677 12.612L6.86677 14.932C7.20011 15.612 7.20011 16.3987 6.86677 17.0787L5.70677 19.3853C1.86677 27.052 5.00011 30.1987 12.6801 26.3587L24.0934 20.652C29.2134 18.092 29.2134 13.9053 24.0934 11.3453ZM19.7868 16.9987H12.5868C12.0401 16.9987 11.5868 16.5453 11.5868 15.9987C11.5868 15.452 12.0401 14.9987 12.5868 14.9987H19.7868C20.3334 14.9987 20.7868 15.452 20.7868 15.9987C20.7868 16.5453 20.3334 16.9987 19.7868 16.9987Z"
        fill="#4460AC"
      />
    </svg>
  )
}

export default SendIcon
